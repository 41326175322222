import React from 'react'
import Carousel from "react-bootstrap/Carousel";
import "../assets/css/smartSecurity.css";
import infoIcon from "../assets/img/info-icon.svg";
import cloudIcon from "../assets/img/cloud-icon.svg";
import headphoneIcon from "../assets/img/headphone-icon.svg";


const AppSmartSecurity = () => {
  return (
    <div className='smart-security'>
      <div className='smart-security-content'>
        {window.innerWidth <= 1001 ? (
          <div>
            <Carousel>
              <Carousel.Item>
                <div className='smart-security-house-position'>
                  <img
                      src={infoIcon}
                      alt='Info Icon'
                      className='info-icon-image'
                  />
                    <h1>
                    Informação em tempo real
                    </h1>
                    <h3>
                    Receba notificações e lembretes sobre qualquer evento que lhe interessar. Sua casa será tão inteligente que poderá reconhecer se você esqueceu de ativar o seu alarme e enviar uma notificação para alertá-lo.
                    </h3>
                  </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className='smart-security-house-position'>
                  <img
                      src={cloudIcon}
                      alt='Cloud Icon'
                      className='cloud-icon-image'
                  />
                    <h1>
                    Vídeo Inteligente
                    </h1>
                    <h3>
                    Vá além da visualização! Combine as imagens com as informações e alertas para tomar as melhores decisões sobre a segurança do seu imóvel.
                    </h3>
                    <br/>
                    <br/>
                    <br/>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className='smart-security-house-position'>
                  <img
                      src={headphoneIcon}
                      alt='Headphone Icon'
                      className='headphone-icon-image'
                  />
                    <h1>
                    Segurança em todos os momento
                    </h1>
                    <h3>
                    Possuímos a Central de Monitoramento 24 horas mais moderna da América Latina para entregar um serviço de excelência.
                    </h3>
                    <br/>
                    <br/>
                    <br/>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        ):(
          <div className='smart-security-content'>
            <div className='smart-security-house-position'>
              <img
                  src={infoIcon}
                  alt='Info Icon'
                  className='info-icon-image'
              />
              <h1>
              Informação em tempo real
              </h1>
              <h3>
              Receba notificações e lembretes sobre qualquer evento que lhe interessar. Sua casa será tão inteligente que poderá reconhecer se você esqueceu de ativar o seu alarme e enviar uma notificação para alertá-lo.
              </h3>
            </div>
            <div className='smart-security-house-position'>
              <img
                  src={cloudIcon}
                  alt='Cloud Icon'
                  className='cloud-icon-image'
              />
                <h1>
                Vídeo Inteligente
                </h1>
                <h3>
                Vá além da visualização! Combine as imagens com as informações e alertas para tomar as melhores decisões sobre a segurança do seu imóvel.
                </h3>
            </div>
            <div className='smart-security-house-position'>
              <img
                  src={headphoneIcon}
                  alt='Headphone Icon'
                  className='headphone-icon-image'
              />
                <h1>
                Segurança em todos os momento
                </h1>
                <h3>
                Possuímos a Central de Monitoramento 24 horas mais moderna da América Latina para entregar um serviço de excelência.
                </h3>
            </div>
          </div>
          
        )}
        
      </div>
    </div>
  )
}

export default AppSmartSecurity